import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26')
];

export const server_loads = [];

export const dictionary = {
		"/": [10],
		"/add-ons": [17,[5]],
		"/add-ons/runs": [20,[5]],
		"/add-ons/[owner]/[repo]": [18,[5]],
		"/add-ons/[owner]/[repo]/[event]": [19,[5]],
		"/app": [21],
		"/documents": [~22,[6]],
		"/documents/projects": [24,[6,8]],
		"/documents/projects/[id]-[slug]": [25,[6,8]],
		"/documents/[id]-[slug]": [~23,[6,7]],
		"/(embed)/documents/[id]": [11,[2,3]],
		"/(embed)/documents/[id]/annotations/[note_id]": [12,[2,3]],
		"/(embed)/documents/[id]/pages/[page]": [13,[2,3]],
		"/(pages)/home": [16,[4]],
		"/(embed)/projects/[project_id]": [14,[2]],
		"/upload": [~26,[9]],
		"/(pages)/[...path]": [15,[4]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';